'use strict';

import { h } from 'hyperapp';
import {getValue} from '../lib/schemasUtils';

/**
 * Basic list component for rendering sequential
 * fields of product based on given schema
 *
 * @param {Object} product
 * @param {Object} schema
 * @returns {XML}
 * @constructor
 */
export function List({ product, schema }) {
  let listItems = [];
  for (let field in schema) {
    if(getValue(field, product)) {
      listItems.push([
        {class:'list-field-name', text: schema[field].displayName},
        {class:'list-field-value', text: getValue(field, product)}
      ]);
    }
  }

  return (
    <div className="list">{
      listItems.map(item =>
        <div className="list-item">{
          item.map(
            node => <div className={node.class}>{ getNodeContent(node.text) }</div>
          )
        }</div>
      )
    }</div>
  );
}

/**
 * Retrieves a list item content
 * based on text to render
 *
 * @param text
 * @returns {*}
 */
function getNodeContent(text) {
  if (isLink(text)) {
    return <a href={text}>{text}</a>
  } else {
    return text;
  }
}

/**
 * Very basic link check, returns true if given text
 * considered a link
 *
 * @param {String} text
 * @returns {Boolean}
 */
function isLink(text) {
  return text.includes('https://') || text.includes('http://');
}



