'use strict';

import {
  h,
  app
} from 'hyperapp';

/**
 * Injects given script URLs and HTML as "root" 
 * in current page. Aims to _completely_ overwrite whatever
 * is currently present on a page in a way that html could
 * be implemented in a way there's nothing apart from it on the page
 * 
 * @param {String[]} scripts 
 * @param {String} html 
 */
function renderExperience(scripts, html, product) {
  document.body.innerHTML = '';
  document.body.removeAttribute('flex-column');
  document.body.removeAttribute('flex-center');

  return loadScripts(scripts)
    .then(() => {
      document.body.innerHTML = render(html, product);
      replaceInlineScripts(document.body);
    });
}

/**
 * Expands moustache templates of given html
 * string with data from a given product
 * 
 * @param {String} html 
 * @param {Object} product 
 */
function render(html, product) {
  const scope = {
    product
  };

  (html.match(/{{\s*[\w\.:]+\s*}}/g) || [])
    .forEach(literal => {
      const key = literal
        .replace('{{', '')
        .replace('}}', '')
        .trim();
      const value = getKey(scope, key);  

      html = html.replace(literal, value)
    });


  return html;
}

/**
 * Retrieves a nested key from a given object
 * 
 * @param {Object} object 
 * @param {String} key 
 */
function getKey(object, key) {
  const parts = key.split('.');
  let part;

  while (part = parts.shift()) {
    object = object[part];
  }

  return object;
}

/**
 * For given array of scripts, tries to load them one-by-one
 * 
 * @param {String[]} srcs 
 */
function loadScripts(srcs) {
  let promise = Promise.resolve();

  srcs.forEach(src => {
    promise = promise
      .then(() => loadScript(src));
  });

  return promise;
}

/**
 * For given element, ensures that all scripts within it
 * are executed even if element was deserialized from a plain html string
 * 
 * @param {HTMLElement} el 
 */
function replaceInlineScripts(el) {
  Array.from(el.querySelectorAll('script'))
    .forEach(script => {
      script.parentElement.removeChild(script);
      document.head.appendChild(cloneScript(script));
    });
}

/**
 * Clones given script element with all its attributes
 * 
 * @param {HTMLElement} el 
 */
function cloneScript(el) {
  const script = document.createElement('script');
  script.text = el.innerHTML;

  for (var i = el.attributes.length - 1; i >= 0; i--) {
    script.setAttribute(el.attributes[i].name, el.attributes[i].value);
  }

  return script
}

/**
 * For a given SRC tries to load it as a script
 * on the page
 * 
 * @param {String} src 
 */
function loadScript(src) {
  return new Promise((res, rej) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = res;
    script.onerror = rej;
    document.head.appendChild(script);
  })
    .catch(err => {
      console.error('ThngView: failed to load and execute: ', src, 'due to:', err);
    });
}

export function CustomExperience({
  product
}) {
  if (product) {
    const experience = product.customFields && product.customFields.custom_experience;
    const scripts = product.customFields && product.customFields.custom_experience_scripts || [];

    if (experience) {
      if (experience.endsWith('.js')) {
        return <script src={ experience }></script>
      }

      // In case experience is fully custom, we're going to 
      // destroy currently rendered application with this call
      renderExperience(scripts, experience, product);
    }
  }
}