'use strict';

import { h } from 'hyperapp';

export function Header({ product }) {
  const experience = product && product.customFields && product.customFields.product_experience_type;

  if (experience === 'smartLabel') {
    return (
      <header class="header fade-in">
        <img
          src="images/smart_label_logo.png"
          alt=""
        />
      </header>
    );
  } 
  else if (experience === 'sac') {
    return (
      <header class="header fade-in">
        <img
          src="/images/Logo_HiggIndex.svg"
          alt=""
        />
      </header>
    );
  } 
  else {
    return (
      <header></header>
    );
  }
}