'use strict';

import { h, app } from 'hyperapp';
import api from '../lib/api'

/**
 * Product info card
 * @param product
 * @returns {XML}
 * @constructor
 */
export function Product({ product }) {
  if (product) {
    return (
      <div class="product fade-in">
        <img
          class="product-photo"
          src={product.photos[0]}
          style={{
            display: product.photos[0] ? 'block' : 'none'
          }}
        />
        <div class="product-main-info">
          <h1 class="product-name">{ product.name }</h1>
          <div class="product-description">{ product.description }</div>
        </div>
      </div>
    )
  }
}

/**
 * Fetches product from EVRYTHNG API and loads it
 * to the view
 */
export function loadProduct() {
  return async (state, actions) => {
    // Show loader if fetching takes more then 1s
    const setLoading = setTimeout(
      () => actions.setLoading(true), 1000
    );

    actions.setProduct(
      await api({
        url: `/products/${state.id}?withScopes=true`
      })
    );

    clearInterval(setLoading);
    actions.setLoading(false);
  }
}

/**
 * Sets the product to state
 */
export function setProduct(product) {
  return state => {
    if (product.name) {
      document.title = product.name;
    }

    return { product: product };
  };
}