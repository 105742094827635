'use strict';

import { h } from 'hyperapp';
import { Facility } from './facility';

export function Facilities({items, schema}) {
  if(items) {
    return (
      <div class="facilities">
        <h4 class="facilities-header">Facilities</h4>
        {items.map(item => (<Facility facility={item} schema={schema}></Facility>))}
      </div>
    )
  }
}

