'use strict';

import {getValue} from '../lib/schemasUtils';
import { BasicScore } from './basic-score';
import { Facilities } from './facilities';
import { h } from 'hyperapp';

export function Sac({ product, schema }) {
  let basicScores = [];
  for (let field in schema) {
    if(getValue(field, product) && schema[field].displayName) {
      basicScores.push({name: schema[field].displayName, score: getValue(field, product)})
    }
  }
  
  
  return (
    <div class="sac">
      {!!basicScores.length && <div class="sac-basic-scores">
        { basicScores.map(i => (<BasicScore value={i.score} name={i.name}></BasicScore>)) }
      </div>}
    {!!product.customFields.facilities.length && 
      <Facilities 
      items={product.customFields.facilities} 
      schema={schema['customFields.facilities'].item}></Facilities>
    }
    </div>
  );

}
