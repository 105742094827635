'use strict';

import { h } from 'hyperapp';
import { Score } from './score';

export function Facility({facility, schema}) {
  let id = Math.random();

  return (
    <label for={id} class="facility">
      <input id={id} type="checkbox"/>
      <div class="facility-title">{facility.name}</div>
      <div class="facility-params">
        { Object.keys(facility).filter(key => key !== 'name').map(key => (
          <div class="facility-param">
            <div class="facility-param-name">{schema[key].displayName}</div>
            <Score value={facility[key]}></Score>
          </div>
        ))}
      </div>


    </label>
  )
}

