'use strict';

import 'whatwg-fetch';
import 'babel-polyfill';
import { h, app } from 'hyperapp';

import { Loader } from './components/loader';
import { Product } from './components/product';
import { Header } from './components/header';
import { List } from './components/list';
import { Sac } from './components/sac';
import { LandingPicture } from './components/landing-picture';
import { CustomExperience } from './components/custom-experience';

import actions from './lib/actions';
import state from './lib/state';
import {getProductSchema} from './lib/schemasUtils';

const smartLabelSchema = getProductSchema('smartLabel');
const SACSchema = getProductSchema('SAC');

const view = (state) => {
  const experience = state.product && state.product.customFields && state.product.customFields.product_experience_type;

  if (state.loading) {
    return <Loader icon={state.icon}></Loader>
  } else {
    if (experience === 'landing_picture') {
      return (
        <container>
          <LandingPicture product={state.product}></LandingPicture>
        </container>
      ) 
    } else if (experience === 'custom') {
      return (
        <container>
          <CustomExperience product={state.product}></CustomExperience>
        </container>
      )
    } else {
      return (
        <container>
          <Header product={state.product}></Header>
          <Product product={state.product}></Product>
          {experience === 'smartLabel' && <List product={state.product} schema={smartLabelSchema}></List> }
          {experience === 'sac' && <Sac product={state.product} schema={SACSchema}></Sac> }
        </container>
      ) 
    }
  }
};


////////////////////////////////////////////////////////////////

window.app = app(state, actions, view, document.querySelector('main'));
window.app.loadProduct();

////////////////////////////////////////////////////////////////