'use strict';

import { h } from 'hyperapp';
import { Score } from './score';

export function BasicScore({value, name}) {
  return (
    <div class="basic-score">
      <Score value={value}></Score>
      <div class="basic-score-title">{name}</div>
    </div>
  );

}