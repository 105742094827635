'use strict';

import { setLoading, rotateIcon } from '../components/loader';
import { loadProduct, setProduct } from '../components/product';

export default {
  loadProduct,
  setProduct,
  setLoading,
  rotateIcon,

  getState: () => state => state
};