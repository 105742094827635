'use strict';

import { h } from 'hyperapp';

export function LandingPicture({product}) {
  if(product) {
    const imageUrl = product.customFields && product.customFields.landing_picture_url;

    return (
      <img class="landing-picture" src={imageUrl}></img>
    );
  }
}

