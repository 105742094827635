'use strict';

import { h } from 'hyperapp';

let loader;
const speed = 1500;
const icons = [
  't-shirt',
  'bulb',
  'heart',
  'pin',
  'smartphone',
  'fridge',
  'gym',
  'camera',
  'cloud'
];

export function Loader({ icon }) {
  return (
    <div class={`loader fade-in ${icon}`}>
      <div className="loader-part loader-part-0"></div>
      <div className="loader-part loader-part-1"></div>
      <div className="loader-part loader-part-2"></div>
      <div className="loader-part loader-part-3"></div>
      <div className="loader-part loader-part-4"></div>
      <div className="loader-part loader-part-5"></div>
    </div>
  );
}

export function setLoading(value) {
  return (state, actions) => {
    if (value) {
      loader = setInterval(() => actions.rotateIcon(), speed)
    } else {
      clearInterval(loader);
    }

    return { loading: value };
  }
}

export function rotateIcon() {
  return state => ({ icon: nextIcon(state.icon) });
}

function nextIcon(current) {
  console.log('CHANGING ICON');
  return icons[(icons.indexOf(current) + 1) % icons.length];
}