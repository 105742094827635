'use strict'

import thngResourceMappings from 'thng-resource-mappings';

const commonFields = ['name', 'description'];

function getProductSchema(productType) {
  let sch;
  if (productType === 'smartLabel') {
    sch = thngResourceMappings.smartLabel.product.recaster;
  }
  if (productType === 'SAC') {
    sch = thngResourceMappings.SAC.product.recaster;
  }
  
  return removeCommonFields(sch);
  
}

function removeCommonFields(schema) {
  commonFields.forEach(field => {
    delete schema[field];
  })
  return schema;
}

function getValue(path, object) {
  path = path.split('.');
  return path.reduce((acc, cur) => (acc && acc[cur]) ? acc[cur] : null, object)
}


export {
  getProductSchema, 
  getValue
};