'use strict';

import { parse } from './qs';
const settings = parse();

export default (config) => {
  const fetchConfig = {
    method: config.method || 'GET',
    headers: {
      'Authorization': settings.key,
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    }
  };

  if (config.body) {
    fetchConfig.body = JSON.stringify(config.body);
  }

  if (!config.url.startsWith('/')) {
    config.url = `/${config.url}`;
  }

  return fetch(
    `${settings.api || 'https://api.evrythng.com'}${config.url}`, fetchConfig
  )
    .then(r => r.json());
}